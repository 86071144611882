section.nav{
    width: 25%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.1rem;
}
nav.mobile{
    display: none;
}
section.nav .nav--container{
    width: 100%;
    height: 100%;
}
section.nav div.logo{
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.nav nav{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column; 
}
section.nav .a-logo{
    margin-right: 2rem;
}
section.nav nav a{
    padding-left: 3rem;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: var(--pl);
}
section.nav nav a:hover{
    background-color: var(--brown-g);
}
section.nav nav a.active{
    position: relative;
    color: #fff;
    background: -webkit-linear-gradient(left, var(--brown-g) , transparent);
}
section.nav nav a.active::after{
    position: absolute;
    left: 0;
    content: '';
    width: 6px;
    height: 70%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: var(--brown);
}
section.pre{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
section.pre img{
    width: 190px;
    height: 150px;
}
/* https://www.figma.com/file/cL4agBQZB6Vaea18QWP9OM/Verse---Payment-Dashboard-UI-Kit-(Community)?type=design&node-id=151-279&mode=design&t=09vBpFbEijn4KVx6-0 */

@media screen and (max-width: 840px) {
    section.pre img{
        width: 90%;
        height: 90%;
    }
} 
@media screen and (max-width: 815px) {
    section.nav{
        display: none;
    }
    nav.mobile{
        display: flex;
        justify-items: center;
        align-items: center;
        position: fixed;
        bottom: 15px;
        width: 100%;
        z-index: 999;
    }
    nav.mobile .container{
        display: flex;
        gap: 20px;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        margin: 0 auto;
        border-radius: 10px;
    }
    nav.mobile .container a.active{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: var(--brown);
        border-radius: 10px;
    }
    
} 